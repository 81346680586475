import * as React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <div className="bg-gray-50">
        <div className="container py-12">
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist.</p>
          <p>
            <Link to="/" className="btn btn-red">
              Return Home
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
